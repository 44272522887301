<template>
	<a-modal v-model="show" title="登记" :width="600" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model :rules="formRule" :model="formData" ref="formRef">
			<a-row :gutter="15">
				<a-col span="12">
					<a-form-model-item label="选择物品" prop="goodsId">
						<selectgoods @input="e => (formData.goodsId = e[0])"></selectgoods>
					</a-form-model-item>
				</a-col>
				<a-col span="12">
					<a-form-model-item label="选择类型" prop="type">
						<a-select v-model="formData.type">
							<a-select-option value="领用">领用登记</a-select-option>
							<a-select-option value="借用">借用登记</a-select-option>
							<a-select-option value="归还">归还登记</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="12">
					<a-form-model-item label="输入物品数量" prop="number">
						<a-input v-model="formData.number" placeholder="请输入物品数量" type="number"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="12">
					<a-form-model-item label="输入备注">
						<a-input v-model="formData.remark" placeholder="请输入备注"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import selectgoods from './selectgoods.vue';

export default {
	components: { selectgoods },
	data() {
		return {
			show: false,
			loading: false,
			formData: {
				goodsId: '',
				type: '',
				number: '',
				remark: ''
			},
			goodsList: [],
			formRule: {
				type: [{ required: true, message: '请选择类型' }],
				goodsId: [{ required: true, message: '请选择物品' }],
				number: [{ required: true, message: '请输入数量' }]
			}
		};
	},
	methods: {
		...apiUtil,
		_show() {
			this.getGoodsList();
			this.show = true;
			this.$nextTick(() => {
				this.$refs.formRef.resetFields();
				this.formData.type = '';
				this.formData.goodsId = '';
				this.formData.number = '';
				this.formData.remark = '';
			});
		},
		_close() {
			this.$refs.formRef.resetFields();
			this.show = false;
			this.$emit('close');
		},
		doSubmit() {
			this.$refs.formRef.validate(valid => {
				if (valid) {
					utils.showSpin();
					request.post('/platform/goods/record/save', this.formData).then(ret => {
						this.show = false;
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								this.$emit('success');
								this.show = false;
							});
						}
					});
				}
			});
		},
		// 获取物品数据
		getGoodsList() {
			// this.http
			// 	.get('/platform/goods/list', this.param)
			// 	.then(ret => {
			// 		this.goodsList = ret.data.records;
			// 	})
			// 	.catch(err => { });
		}
	}
};
</script>

<style scoped></style>
